export class CommonAccordion {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.trg = wrapper.getElementsByClassName(CommonAccordion.trgClassName)[0];
        this.bindEvent();
    }
    bindEvent() {
        if (!(this.trg instanceof HTMLElement)) {
            return;
        }
        this.trg.addEventListener('click', (e) => {
            if (!(e.target instanceof HTMLElement)) {
                return;
            }
            const target = e.target;
            if (target.classList.contains(CommonAccordion.trgClassName) ||
                target.closest(`.${CommonAccordion.trgClassName}`) !== null) {
                const parent = this.wrapper;
                if (!parent.classList.contains(CommonAccordion.toggleClassName)) {
                    parent.classList.add(CommonAccordion.toggleClassName);
                }
                else if (parent.classList.contains(CommonAccordion.toggleClassName)) {
                    parent.classList.remove(CommonAccordion.toggleClassName);
                }
            }
        }, false);
    }
    static init() {
        const target = document.getElementsByClassName('js-acc');
        if (target.length) {
            for (let i = 0; i < target.length; i++) {
                if (!(target[i] instanceof HTMLElement)) {
                    continue;
                }
                const thisTarget = target[i];
                new CommonAccordion(thisTarget);
            }
        }
    }
}
CommonAccordion.trgClassName = 'js-acc__trg';
CommonAccordion.toggleClassName = 'is-open';

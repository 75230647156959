import '@babel/polyfill';
require('element-closest-polyfill');
import { ResizeManager } from './module/common/resize-manager';
import { Loading } from './module/common/loading';
import { CommonScroll } from './module/common/common-scroll';
import { SetGnav } from './module/common/set-gnav';
import { Placeholder } from './module/common/placeholder';
import { SetWidth } from './module/common/set-width';
import { SmoothScroll } from './module/common/smooth-scroll';
import { CommonAccordion } from './module/common/common-accordion';
import { CommonTab } from './module/common/common-tab';
import { hogefn } from './module/common/test';
document.addEventListener('DOMContentLoaded', () => {
    const resizeManager = new ResizeManager();
    new Loading();
    new SetGnav();
    new CommonScroll('js-inview');
    Placeholder.init();
    SetWidth.init();
    SmoothScroll.init();
    CommonAccordion.init();
    CommonTab.init();
    resizeManager.add(hogefn);
    resizeManager.init();
}, false);

export class SmoothScroll {
    constructor(target) {
        this.target = target;
        this.startPositionX = 0;
        this.startPositionY = 0;
        this.endPositionX = 0;
        this.endPositionY = 0;
        this.startTime = 0;
        this.animationId = 0;
        this.duration = 700;
        this.bindEvent();
    }
    bindEvent() {
        this.target.addEventListener('click', (e) => {
            if (!(e.target instanceof HTMLElement)) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            if (e.target === null) {
                return;
            }
            const eventTarget = e.target;
            let clickedTarget = null;
            if (!(eventTarget instanceof HTMLAnchorElement)) {
                clickedTarget = eventTarget.closest('a[href^="#"]');
            }
            else if (eventTarget instanceof HTMLAnchorElement) {
                clickedTarget = eventTarget;
            }
            if (clickedTarget === null) {
                return;
            }
            let targetY;
            if (clickedTarget.classList.contains('js-go-to-top')) {
                targetY = 0;
            }
            else {
                const destinationElmId = clickedTarget.getAttribute('href');
                if (destinationElmId === null) {
                    return;
                }
                if (destinationElmId === '#') {
                    return;
                }
                const destinationElm = document.getElementById(destinationElmId.replace('#', ''));
                if (destinationElm === null ||
                    !(destinationElm instanceof HTMLElement)) {
                    return;
                }
                const documentHeight = document.body.clientHeight;
                if (destinationElm.offsetTop + window.innerHeight > documentHeight) {
                    targetY = documentHeight - window.innerHeight;
                }
                else {
                    targetY =
                        destinationElm.getBoundingClientRect().top + window.pageYOffset;
                }
            }
            this.exeScroll(targetY);
        }, false);
    }
    getEasing(num) {
        return 1 - Math.pow(1 - num, 4);
    }
    animation() {
        const progress = Math.min(1, (Date.now() - this.startTime) / this.duration);
        const scrollValX = this.startPositionX +
            (this.endPositionX - this.startPositionX) * this.getEasing(progress);
        const scrollValY = this.startPositionY +
            (this.endPositionY - this.startPositionY) * this.getEasing(progress);
        window.scrollTo(scrollValX, scrollValY);
        if (progress < 1) {
            this.animationId = requestAnimationFrame(() => {
                this.animation();
            });
        }
    }
    cancelScroll() {
        window.cancelAnimationFrame(this.animationId);
    }
    exeScroll(destinationY, destinationX) {
        this.startPositionX = window.scrollX;
        this.startPositionY = window.scrollY;
        this.endPositionX = destinationX != null ? destinationX : window.scrollX;
        this.endPositionY =
            destinationY != null ? destinationY - 100 : window.scrollY;
        this.startTime = Date.now();
        this.animation();
    }
    static init() {
        const target = document.querySelectorAll('a[href^="#"]');
        if (target.length) {
            for (let i = 0; i < target.length; i++) {
                if (!(target[i] instanceof HTMLAnchorElement)) {
                    continue;
                }
                const thisTarget = target[i];
                new SmoothScroll(thisTarget);
            }
        }
    }
}
